.deal-checkout {
  &__sales-team-member {
    img {
      width: 40px;
      border-radius: 50%;
    }
  }

  .paypal-buttons-disabled {
    pointer-events: none;
  }

  &__pricing {
    .product-info {
      display: flex;
      margin-bottom: 30px;
    }

    .product-image {
      height: 145px;
      width: 145px;
      border-radius: 4px;
      background-size: cover;
      background-position: center;
      background-color: #eaeaea;
      margin: 0 auto;

      &-container {
        margin-right: 10px;
      }
    }

    .product-description {
      @media (min-width: 375px) {
        min-width: 160px;
      }

      font: var(--elo-body-sm-medium);
      line-height: 1.3;
    }
  }
}

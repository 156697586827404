.custom-shop {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1;
  }
}

.iframed-custom-shop {
  height: unset;
}

.iframed-transparent {
  .payment-page {
    background-color: transparent;
  }
}

@import 'src/ui-kit/styles/colors';

.content-pagebuilder-view {
  img,
  iframe {
    max-width: 100%;
    // produce bug with embeded youtube video
    // @media (max-width: 767px) {
    //   height: auto!important;
    // }
  }

  &--col {
    display: flex;
  }
  // Start of rows logic
  .content-blocks-row {
    @include make-row();
    margin: 0;
    .content-block {
      @include make-col-ready();
      padding: 0;
    }

    &.childs-count-1 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
    }
    &.childs-count-2 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
    &.childs-count-3 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
      .text-side {
        max-width: 90%;
        .text {
          max-width: 88%;
        }
      }
    }
    &.childs-count-4 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
      .text-side {
        max-width: 85%;
        .text {
          max-width: 80%;
        }
      }
    }
    &.childs-count-5 .content-block {
      @include media-breakpoint-up(lg) {
        max-width: 20%;
        flex: 0 0 20%;
      }
      .text-side {
        max-width: 80%;
        .text {
          max-width: 75%;
        }
      }
    }
    &.childs-count-6 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
      .text-side {
        max-width: 75%;
        .text {
          max-width: 70%;
        }
      }
    }
  }

  .content-blocks-row.sticky-header {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .content-blocks-col {
    display: block;
    margin: 0;
    .content-block {
      @include make-col-ready();
      padding: 0;
      display: block;
    }
    &.childs-count-1 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
    }
    &.childs-count-2 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
    &.childs-count-3 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
      .text-side {
        max-width: 90%;
        .text {
          max-width: 88%;
        }
      }
    }
    &.childs-count-4 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
      .text-side {
        max-width: 85%;
        .text {
          max-width: 80%;
        }
      }
    }
    &.childs-count-5 .content-block {
      @include media-breakpoint-up(lg) {
        max-width: 20%;
        flex: 0 0 20%;
      }
      .text-side {
        max-width: 80%;
        .text {
          max-width: 75%;
        }
      }
    }
    &.childs-count-6 .content-block {
      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
      .text-side {
        max-width: 75%;
        .text {
          max-width: 70%;
        }
      }
    }
  }
  // End of rows logic

  .lesson-block-filelist,
  .content-block-filelist {
    .file-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $elo-grey-dark;

      .file-item-left-side {
        display: flex;

        .file-item-preview,
        .cover__img,
        .cover__no-cover {
          margin-right: 10px;
        }

        .file-item-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        i {
          color: $elo-grey;
          font-size: 37px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $elo-grey-light;
      }
      .audio-player,
      .mobile-player {
        .audio-button {
          &:before {
            content: '\f04b';
          }
          &.play {
            &:before {
              content: '\f04c';
            }
          }
          audio {
            display: none;
          }
        }
      }
      .text-side {
        display: flex;
        align-items: center;
        .text {
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        img {
          width: 35px;
          height: 35px;
          margin-right: 8px;
          border-radius: 4px;
        }
        i {
          margin-right: 8px;
          font-size: 24px;
          width: 35px;
          text-align: center;
        }
      }
      .styled-download-btn {
        border: 1px solid $elo-blue !important;
        background: $elo-white !important;
        color: $elo-blue !important;
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 60px;

    &--no-margin {
      margin-top: 0;
    }
  }
}

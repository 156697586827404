@import 'src/ui-kit/styles/colors';

.paymethods {
  .payment-method-label {
    display: block;
    cursor: pointer;
    margin-bottom: 4px;
  }

  .payment-method {
    border-radius: 6px;
    position: relative;

    &::before {
      content: '';
      border: solid 2px $elo-grey-light;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &.selected {
      box-shadow: 0 2px 10px 1px rgba($elo-black, 0.15);

      &::before {
        border: none;
      }

      .select-paymethod {
        cursor: default;
        border-radius: 6px 6px 0 0;

        img {
          opacity: 1;
        }
      }

      .paymethod-content,
      .new-paymethod-content {
        border-radius: 0 0 6px 6px;

        select:invalid,
        select option[value=''] {
          color: $elo-grey-40;
        }

        .form-group input,
        .form-group select {
          &::placeholder {
            color: $elo-grey-40;
          }

          option:first-child {
            color: $elo-grey-40;
          }

          font-family: var(--elo-font-sans-serif), serif;
          font-size: 13px;
          line-height: 1.9;
        }
      }
    }

    .select-paymethod {
      cursor: pointer;
      height: 57px;
      padding: 0 19px 0;
      border-radius: 6px;
      background-color: $elo-white;

      .select-paymethod__img-wrapper {
        padding-left: 0;
      }

      & > div > div:first-child {
        padding-right: 0;
      }

      .method-container {
        height: 100%;

        label {
          margin-bottom: 0;
        }

        .label-container {
          height: 22px;
        }
      }

      .radio-field.de {
        label[for='pay_later'] {
          @media (max-width: 401px) {
            width: 93px;
            font-size: 13px;
            line-height: 1;
          }
        }
      }

      .custom-check {
        font-family: var(--elo-font-sans-serif), serif;
        line-height: 26px;
        color: $elo-black-semi;
      }

      img {
        opacity: 0.5;
        height: 20px;
        width: auto;

        & + img {
          margin-left: 10px;
        }

        &.ideal-img {
          height: 45px;
        }

        @media (max-width: 455px) {
          &.card-img.stripe,
          &.card-img.elopage_connect {
            max-width: 120px;
          }

          & + img {
            margin-left: auto;
          }
        }

        @media (max-width: 386px) {
          &.card-img {
            &.mango_pay,
            &.elopage_connect,
            &.lemonway,
            &.stripe {
              max-width: 70px;
            }
          }
        }

        &.pay_later-img {
          &.de {
            height: 29px;

            @media (max-width: 376px) {
              height: 23px;
            }
          }
        }

        @media (max-width: 376px) {
          &.pay_later-img {
            height: 17px;
          }
        }
      }

      .p_24-img {
        height: 24px;
      }
    }
  }

  .paymethod-content,
  .new-paymethod-content {
    background-color: $elo-grey-light-20;
    font-size: 13px;
    padding: 40px 15px;
    position: relative;

    #card-errors {
      color: $elo-red-60;
    }

    .pay-info,
    .credit-card-info {
      text-align: center;
      color: $elo-grey;
      line-height: 1.5;
    }

    .new-pay-info {
      font: var(--elo-body-xs-regular);
      color: var(--color-primary-dg-500);
      text-align: center;
    }

    .credit-card-info {
      margin-top: 15px;
      font-style: normal;
    }

    .help-info {
      text-align: center;
      border-top: 1px solid rgba($elo-grey, 0.2);
      padding-top: 20px;
      margin-top: 20px;
      font-size: 12px;
      font-style: normal;
      color: $elo-grey;
      line-height: 1.5;

      &--without-separator {
        padding-top: 0;
        border-top: none;
        word-break: break-all;
      }
    }

    .new-help-info {
      font: var(--elo-body-xs-regular);
      color: var(--color-primary-dg-500);
      margin-top: 16px;
      border-top: 1px solid var(--color-primary-dg-200);
      padding-top: 20px;
      text-align: center;

      &--without-separator {
        padding-top: 0;
        border-top: none;
      }
    }

    .card-secure-info {
      opacity: 0.4;
      text-align: left;
      font-size: var(--elo-font-size-xs);
      color: $elo-grey;
      margin-bottom: 3px;
      position: absolute;
      right: 15px;
      top: 21px;
      display: flex;
      align-items: baseline;

      .fa-lock {
        font-size: 13px;
      }
    }

    .new-card-label {
      font: var(--elo-body-sm-medium);
      color: var(--color-primary-neutral-black);
      margin-bottom: 4px;

      &--flex {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .new-card-tooltip {
      &__label {
        font: var(--elo-body-xs-regular);
        color: var(--color-primary-dg-brand);
        margin-bottom: 8px;
      }

      &__flex {
        display: flex;
        align-items: center;
        gap: 16px;

        &--label {
          font: var(--elo-body-xs-semibold);
          color: var(--color-primary-dg-500);
        }

        &--text {
          font: var(--elo-body-xs-regular);
          color: var(--color-primary-dg-400);
        }

        &--image {
          height: 90px;
        }
      }
    }

    .new-card-input {
      height: 48px;
      padding: 16px;

      &::placeholder {
        font: var(--elo-body-sm-regular) !important;
        color: var(--color-primary-dg-300) !important;
      }
    }

    .new-card-select {
      height: 48px;
      padding: 16px !important;
    }
  }

  .new-paymethod-content {
    background-color: var(--color-primary-cg-100);
    padding: 24px 16px;

    &--with-bottom-margin {
      margin-bottom: 16px;
    }

    .payment-method-label {
      font: var(--elo-body-sm-medium);
      margin-bottom: 4px;
    }

    .old-payment-method-selector {
      border: solid 1px $elo-grey-40;
    }

    .payment-method-selector {
      border: 1px solid var(--color-primary-dg-200);
      border-radius: 4px;
      padding: 1px;
    }

    .new-payment-method-input {
      height: 48px;
      padding: 16px;
      box-shadow: unset;

      &::placeholder {
        font: var(--elo-body-sm-regular) !important;
        color: var(--color-primary-dg-300) !important;
      }
    }

    .new-card-block {
      padding: 16px;
      border: 1px solid var(--color-primary-dg-200);
      border-radius: 4px;
      background-color: var(--color-primary-neutral-white);
    }
  }

  &--visual-separation {
    display: flex;
    align-items: center;
    margin: 25px 0;
    font-size: var(--elo-font-size-md);
    font-family: var(--elo-font-sans-serif);
    max-width: 100%;

    hr {
      margin: 0 50px 0 0;
      border-top: 2px solid $elo-black;
      max-width: 450px;
      width: 100%;

      &:last-child {
        margin-right: 0;
        margin-left: 50px;
      }
    }
  }
}

.new-paymethods {
  .payment-method-card {
    margin-bottom: 16px;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 8px;

      .elo-radio-button {
        margin-bottom: 0;
        width: 100%;
      }
    }

    &__full-width {
      width: 100%;
    }

    &__logos {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      img {
        width: auto;

        @media (max-width: 420px) {
          max-width: 120px;
        }

        @media (max-width: 360px) {
          max-width: 70px;
        }
      }
    }

    &--without-margin {
      margin-bottom: 0;
    }
  }

  .payment-methods-desktop-tablet {
    display: block;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .payment-main-methods,
  .payment-rest-methods {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;

    &__content {
      .elo-radio-button {
        margin-bottom: 0;
      }
    }

    &__logos {
      white-space: nowrap;
      margin-bottom: 16px;

      img {
        width: auto;
        height: 12px;
      }

      span {
        margin-left: 16px;
        padding: 0 8px;
        border-radius: 8px;
        background-color: var(--color-primary-cg-brand);
        font: var(--elo-body-xs-medium);
      }
    }

    .elo-card {
      min-width: unset;
    }

    .payment-method-label {
      flex: 1 1 0;
      min-width: 144px;
    }
  }

  .payment-rest-methods {
    flex-wrap: wrap;
    margin-bottom: 0;

    .elo-card {
      min-width: 125px;
    }
  }

  .payment-mobile-methods {
    display: none;

    .scroll-wrapper {
      overflow-x: auto;
      scrollbar-width: none;
      display: flex;
      justify-content: space-between;
      column-gap: 16px;
      padding: 2px 4px;
      margin-bottom: 16px;
    }

    .scroll-wrapper::-webkit-scrollbar {
      display: none;
    }

    &__content {
      .elo-radio-button {
        margin-bottom: 0;
      }
    }

    &__logos {
      margin-bottom: 16px;

      img {
        width: auto;
        height: 12px;
      }
    }

    .elo-card {
      min-width: 144px;
    }

    .payment-method-label {
      flex: 1 1 0;

      .elo-radio-button {
        &__label {
          white-space: nowrap;
        }
      }
    }

    @media (max-width: 576px) {
      display: block;
    }
  }

  .payment-method-visual-separation {
    font: var(--elo-body-sm-regular);
    color: var(--color-primary-dg-500);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 16px;

    div {
      height: 1px;
      width: 100%;
      background-color: var(--color-primary-dg-400);
    }
  }

  .payment-method-accordion {
    margin-bottom: 16px;
  }
}
